import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, ...rest }, ref) => {
  return (
    <input
      className={clsx(
        'form-input w-full rounded-md border border-stone-300 bg-white shadow-sm outline-none placeholder:text-stone-400 focus:border-stone-400 focus:ring-0 disabled:bg-inkanPlaceholder dark:bg-stone-900 dark:placeholder-stone-400',
        className
      )}
      {...rest}
      ref={ref}
    />
  );
});

Input.displayName = 'Input';
export default Input;
